	/*
  	Flaticon icon font: Flaticon
  	Creation date: 15/09/2020 10:35
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^='flaticon-'],
[class*=' flaticon-'],
[class^='flaticon-'],
[class*=' flaticon-'] {
	line-height: 1;
}

[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-benefit:before { content: "\f100"; }
.flaticon-healthcare:before { content: "\f101"; }
.flaticon-gymnast:before { content: "\f102"; }
.flaticon-doctor:before { content: "\f103"; }
.flaticon-phone:before { content: "\f104"; }
.flaticon-home:before { content: "\f105"; }
.flaticon-message:before { content: "\f106"; }
.flaticon-home-1:before { content: "\f107"; }
.flaticon-virus:before { content: "\f108"; }
.flaticon-temperature:before { content: "\f109"; }
.flaticon-social:before { content: "\f10a"; }
.flaticon-handwash:before { content: "\f10b"; }
.flaticon-meditation:before { content: "\f10c"; }
.flaticon-quality:before { content: "\f10d"; }
.flaticon-parking:before { content: "\f10e"; }
.flaticon-handshake:before { content: "\f10f"; }
.flaticon-error:before { content: "\f110"; }
